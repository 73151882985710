<template>
  <div v-if="reception" class="row overflow-auto">
    <div class="col-12 overflow-auto">

      <div class="row overflow-auto p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Pick boutique #{{ id_pick }}</h1>
        </div>
      </div>

      <div class="row justify-content-center overflow-auto">
        <div class="col-12 overflow-auto px-5 pt-5">
          <router-link :to="'/picks/'" class="btn btn-primary btn-lg text-decoration-none">
            <i class="bi bi-arrow-left-circle"></i> Retour à la liste des picks
          </router-link>
        </div>
      </div>

      <!-- Détails d'un pick  -->
      <div v-if="products" class="row justify-content-center overflow-auto">
        <div class="col-12 overflow-auto px-5 pt-5">

          <table class="table text-center table-hover align-middle">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col" @click="sortByColumn('sku')">SKU</th>
                <th scope="col" @click="sortByColumn('name')">Nom</th>
                <th scope="col" @click="sortByColumn('quantity_picked')">Quantité envoyée</th>
              </tr>
            </thead>

            <tbody class="table-group-divider">
              <tr v-for="(product, key) in reception" :key="key">
                <td class="py-3">
                  <img v-if="products[product.SKU]?.media_gallery_entries?.length > 0" class="img-fluid h-64" :src="productImageUrl(products[product.SKU].media_gallery_entries[0].file)" alt="Product Image">
                  <img v-else class="img-fluid h-64" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
                </td>
                <td class="py-3">{{ product.SKU }}</td>
                <td class="py-3">{{ products[product.SKU]?.name }}</td>
                <td class="py-3">{{ product.quantity_picked }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-else class="row justify-content-center overflow-auto">
        <div class="col-12 overflow-auto px-5 pt-5">
          <loader />
        </div>
      </div>

    </div>
  </div>

  <div v-else-if="receptions.length" class="row overflow-auto">
    <div class="col-12 overflow-auto">

      <div class="row overflow-auto p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Pick boutiques de {{ user.store.name }}</h1>
        </div>
      </div>

      <!-- Liste des picks -->
      <div class="row justify-content-center overflow-auto">
        <div class="col-12 overflow-auto px-5 pt-5">
          <table class="table text-center table-hover align-middle">
            <thead>
              <tr>
                <th scope="col" @click="sortByColumn('date_add')">Date de création</th>
                <th scope="col" @click="sortByColumn('id')">ID</th>
                <th scope="col" @click="sortByColumn('picked')">Expédié ?</th>
                <th scope="col" @click="sortByColumn('receipt')">Terminé ?</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr v-for="(reception, index) in receptions" :key="index">
                <td class="py-3">{{ formatDate(reception.date_add, false).date }}</td>
                <td class="py-3">
                  <router-link :to="'/picks/' + reception.id" class="btn btn-sm btn-primary text-decoration-none">
                    {{ reception.id }}
                  </router-link>
                </td>
                <td v-if="reception.picked" class="py-3">
                  <i class="bi bi-check-circle text-success"></i>
                </td>
                <td v-else class="py-3">Non</td>
                <td v-if="reception.receipt == 1" class="py-3 d-flex flex-column">
                  <span class="mb-2"><i class="bi bi-check-circle text-success"></i></span>
                  <span>{{ formatDate(reception.date_receipt, false).full }}</span>
                </td>
                <td v-else class="py-3">
                  <button class="btn btn-sm btn-success" @click="validateStoreReception({ id_shop: reception.id_shop, id_pick: reception.id })">Terminer</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="row h-100 overflow-auto">
    <loader />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatDate, productImageUrl } from '@/utils/tools';
import productService from "@/services/productService";

export default {
  props: {
    id_pick: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      products: null,
    };
  },
  watch: {
    'user.store': {
      immediate: true,
      handler(new_store, old_store) {
        if (new_store != old_store) {
          this.fetchStoreReceptions(new_store.id_shop);
        }
      },
    },
    id_pick: {
      immediate: true,
      async handler(id_pick) {
        if (id_pick) {
          await this.fetchStoreReception({
            id_shop: this.user.store.id_shop,
            id_pick: id_pick,
          });
          await this.loadProducts();
        } else {
          this.fetchStoreReceptions(this.user.store.id_shop);
          this.clearReception()
        }
      },
    },
  },
  computed: {
    ...mapGetters('cookie', [
      'user'
    ]),
    ...mapGetters('reception', [
      'receptions', 
      'reception'
    ]),
  },
  methods: {
    formatDate,
    productImageUrl,
    ...mapActions('reception', [
      'fetchStoreReceptions',
      'fetchStoreReception',
      'validateStoreReception',
      'clearReception',
    ]),
    async loadProducts() {
      if (this.reception) {
        // Récupérer les SKUs depuis les valeurs de `reception`
        const skus = Object.values(this.reception).map((product) => product.SKU);
        const products = await Promise.all(
          skus.map(async (sku) => {
            try {
              const result = await productService.getProductBySku(sku);
              return { sku, details: result || { name: 'Produit inconnu', image: null } };
            } catch (error) {
              console.error(`Erreur lors de la récupération du produit pour le SKU: ${sku}`, error);
              return { sku, details: { name: 'Produit inconnu', image: null } };
            }
          })
        );

        // Créer un dictionnaire des produits complets
        this.products = Object.fromEntries(
          products.map(({ sku, details }) => [sku, details])
        );
      }
    },
  },
  mounted() {
    this.fetchStoreReceptions(this.user.store.id_shop);
  },
};
</script>
