<template>
  <div class="row h-100 bg-light justify-content-center align-items-center">
    <div class="col-xl-8 col-md-10">

      <div class="row h-100 overflow-auto">
        <div class="col h-100 overflow-auto">

          <div class="row mb-4">
            <div class="col-12 text-center">
              <h1>Créer un nouveau panier</h1>
            </div>
          </div>
      
          <!-- Message si aucun store n'est disponible -->
          <div v-if="user.stores && user.stores.length && user.stores.length == 0" class="row">
            <div class="col-12">
              <div class="alert alert-warning text-center">
                Aucun magasin disponible pour créer un panier.
              </div>
            </div>
          </div>

          <!-- Affichage des stores -->
          <div v-else-if="user.stores && user.stores.length > 1" class="row">
            <div v-for="store in user.stores" :key="store.id_store" class="col-12 col-md-6 mb-4">
              <div class="card h-100" role="button" @click="onCreateCart(store)">
                <div class="card-body text-center">
                  <i class="bi bi-shop fs-1"></i>
                  <h5 class="card-title mt-3">{{ store.name }}</h5>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('cookie', ['user']),
  },
  methods: {
    ...mapActions('cart', ['createCart']),
    ...mapActions('user', ['fetchUserStores']),
    async onCreateCart(store) {
      this.$emit('close-create-cart');
      await this.createCart({ user: this.user, store });
        this.$router.push(`/carts/${this.$store.state.cart.cart.id}`);
    }
  },
  async mounted() {
    await this.fetchUserStores();
    if (this.user.stores.length == 1) {
      this.onCreateCart(this.user.stores[0]);
    }
  }
}
</script>
