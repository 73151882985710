<template>
  <div class="row justify-content-center mt-5">
    <div class="col-auto h5 fw-bold">Paiements</div>
  </div>
  <div v-if="order.payment" class="row justify-content-center">
    <div class="col">
      <table class="table border align-middle fs-sm">
        <tbody>
          <tr>
            <td class="col-3 text-start">{{ order.payment.method }}</td>
            <td v-if="order.payment.cc_last4" class="col text-center">xxxx-xxxx-xxxx-{{ order.payment.cc_last4 }}</td>
            <td class="col-3 text-end fw-bold">{{ order.payment.amount_ordered }} €</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <div class="row justify-content-start mb-3">
    <div class="col">
      <select v-if="selectPaymentMethod" class="form-select form-select-sm" v-model="payment_method">
        <option value="0" disabled selected>Choisir un mode de paiement</option>
        <option value="alma_pay_pos">Alma (3x sans frais)</option>
        <option value="cb">Carte Bancaire</option>
        <option value="checkmo">Double Paiement</option>
        <option value="especes">Espèces</option>
      </select>
    </div>
    <div class="col-auto">
      <button v-if="payment_method" class="btn btn-sm float-end btn-success" @click="updatePaymentMethod()">Appliquer ce mode de paiement</button>
      <button v-else class="btn btn-sm float-end btn-outline-primary" @click="togglePaymentMethodForm()">Changer le mode de paiement</button>
    </div>
  </div>

</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectPaymentMethod: null,
      payment_method: 0,
    };
  },
  methods: {
    ...mapActions('order', [
      'updateOrderPaymentMethod',
    ]),
    togglePaymentMethodForm() {
      this.selectPaymentMethod = !this.selectPaymentMethod;
      if (!this.selectPaymentMethod) {
        this.payment_method = null;
      }
    },
    updatePaymentMethod() {
      this.updateOrderPaymentMethod({ payment_method: this.payment_method });
      this.togglePaymentMethodForm();
    },
  }
};
</script>