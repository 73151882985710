<template>
  <div id="receipt" class="row flex-grow-1 text-center h-100">
    <div class="col-12">
    <!-- Ticket de Caisse -->
      <div class="row mb-4">
        <div class="col-12">
          <img class="img-fluid" :src="require('@/assets/img/logos/espaceplaisir-condensed-red.png')" alt="Logo Espaceplaisir" />
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          {{ order.billing_address.street[0] }}<br>
          {{ order.billing_address.postcode }} {{ order.billing_address.city }}
        </div>
      </div>

      <div class="row border-top border-bottom border-black mb-4">
        <div class="col-12 px-0 py-3">
          <div class="row text-start">
            <div class="col-6">
              <strong>Date : </strong>{{ formatDate(order.created_at, +1).full }}<br>
            </div>
            <div class="col-6">
              <strong>Cmd : </strong> #{{ order.increment_id }}<br>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 px-0">
          <table class="table fs-sm mb-0">
            <thead class="border-bottom border-black">
              <tr>
                <th class="text-start px-0">Produits</th>
                <th v-if="withPrice" class="text-end px-0">Montant</th>
              </tr>
            </thead>
            <tbody class="border-0">
              <tr v-for="(product, index) in data.items" :key="index" class="border-0">
                <td v-if="product.price" class="border-0 text-start px-0">{{ product.qty }}x {{ detailledFormat ? product.name : 'Article'}}<br>{{ detailledFormat ? product.sku : ''}}</td>
                <td v-if="product.price" class="border-0 text-end px-0">{{ withPrice ? product.base_row_total_incl_tax + '€' : '' }}</td>
              </tr>
            </tbody>
            <tfoot v-if="withPrice" class="border-top border-black">
              <tr class=" fw-bold">
                <td class="text-start px-0">Total TTC</td>
                <td class="text-end px-0">{{ data.base_grand_total }} €</td>
              </tr>
            </tfoot>
          </table>
          <table v-if="withPrice" class="table fs-sm">
            <tbody>
              <tr>
                <td class="border-0 text-start px-0">Total HT</td>
                <td class="border-0 text-end px-0">{{ data.base_subtotal }} €</td>
              </tr>
              <tr>
                <td class="border-0 col-auto text-start px-0">Total TVA</td>
                <td class="border-0 col-auto text-end px-0">{{ data.base_tax_amount }} €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <strong>Aidez-nous à faire toujours mieux !</strong><br>
          <br>
          Nous vous offrons <strong>10 €</strong> en bon d'achat à valoir sur votre prochaine commande en laissant un avis sur Google.<br>
          <br>
          Découvrez encore plus de produits sur notre boutique en ligne :<br>
          <u>www.espaceplaisir.fr</u>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate } from '@/utils/tools';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    detailledFormat: {
      type: Boolean,
      default: true,
    },
    withPrice: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
  },
  methods: {
    formatDate,
  },
}
</script>
