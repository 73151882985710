<template>
  <div class="row justify-content-center my-3">
    
    <div class="col text-start">
      <button @click="emitCollapseSidebar" class="btn btn-sm btn-secondary" title="Cacher le volet du résumé de la commande">
        <i class="bi bi-layout-sidebar-inset"></i>
      </button>
    </div>

    <div class="col text-center">
      <img :src="sourceIcon(order.store_id)" class="img-fluid h-48" />
    </div>

    <div class="col text-end">
      <a :href="'https://shopadmin.espaceplaisir.fr/suadmin/sales/order/view/order_id/' + order.entity_id" target="_blank" class="btn btn-sm btn-secondary">
        <i class="bi bi-copy"></i>
      </a>
    </div>
  </div>

  <div class="row justify-content-center mb-3">
    <div class="col-xxl-7 col-10 h4 fw-bold bg-white border text-center p-3">
      # {{ order.increment_id }}
    </div>
  </div>

  <div class="row flex-column align-items-center">
    <div class="col-auto fs-5 fw-bold">
      {{ order.customer_firstname }} {{ order.customer_lastname }}
    </div>
    <div class="col-auto fs-sm">
      {{ order.customer_email }}
    </div>
  </div>

</template>

<script>
import { sourceIcon } from '@/utils/tools';
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sourceIcon,
    emitCollapseSidebar() {
      this.$emit('collapse-sidebar');
    },
  },
};
</script>
