import backend from '@/services/backendService';

class SearchService {
  createSearchParams(filters, operation, limit, fields, sortField = 'created_at', sortDirection = 'DESC', currentPage = 1) {
    const filterGroups = [];
    let date_start = '';
    let date_end = '';
  
    Object.entries(filters).forEach(([field, filter]) => {
      const { value, condition } = filter;
  
      // Gestion spécifique pour total_paid_min et total_paid_max
      if (field === 'total_paid_min' || field === 'total_paid_max') {
        const newField = 'total_paid';
        const newCondition = field === 'total_paid_min' ? 'gteq' : 'lteq';
  
        filterGroups.push({
          filters: [
            {
              field: newField,
              value: value,
              condition_type: newCondition,
            },
          ],
        });
      }
  
      // Gestion de plage de dates
      else if (value[0] && value[1] && Array.isArray(value) && condition == 'range') {
        date_start = value[0] + '+00:00:00';
        date_end = value[1] + '+23:59:59';
  
        filterGroups.push({
          filters: [
            {
              field: `${field}`,
              value: `${date_start}`,
              condition_type: 'gteq',
            },
          ],
        });
        filterGroups.push({
          filters: [
            {
              field: `${field}`,
              value: `${date_end}`,
              condition_type: 'lteq',
            },
          ],
        });
      }
  
      // Gestion des autres champs
      else if (value && !Array.isArray(value) && operation == 'or' && field != 'type_id' && field != 'status') {
        if (!filterGroups.length) {
          filterGroups.push({ filters: [] });
        }
  
        filterGroups[0].filters.push({
          field: field,
          value: value,
          condition_type: condition,
        });
      }
  
      // Gestion des filtres simples
      else if (value && !Array.isArray(value)) {
        filterGroups.push({
          filters: [
            {
              field: field,
              value: value,
              condition_type: condition,
            },
          ],
        });
      }
    });
  
    const params = {
      'searchCriteria[filter_groups]': filterGroups,
      'searchCriteria[pageSize]': limit ?? 100,
      'searchCriteria[currentPage]': currentPage, // Ajout de la page actuelle
      'searchCriteria[sortOrders][0][field]': sortField,
      'searchCriteria[sortOrders][0][direction]': sortDirection,
    };
  
    if (fields && fields.length > 0) {
      params.fields = `items[${fields.join(',')}],total_count`;
    }
  
    return params;
  }
  
  

  async fetchResults(endpoint, params) {
    try {
      const response = await backend.get(endpoint, params);
      return response.items;
    } catch (error) {
      console.error(`Erreur lors de la recherche à l'endpoint ${endpoint} :`, error.message);
      throw error;
    }
  }

  async searchProducts(filters = {}, operation = null, limit = null, fields = []) {
    const params = this.createSearchParams(filters, operation, limit, fields);
    return this.fetchResults('/search/products', params);
  }

  async searchCustomers(filters = {}, operation = null) {
    const params = this.createSearchParams(filters, operation);
    return this.fetchResults('/search/customers', params);
  }

  async searchCarts(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/carts', params);
  }

  async searchOrders(filters = {}, operation = null, limit = null, fields = [], currentPage = 1) {
    const params = this.createSearchParams(filters, operation, limit, fields, 'created_at', 'DESC', currentPage);
    return this.fetchResults('/search/orders', params);
  }

  async searchSalesRules(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/salesrules', params);
  }

  async searchCoupons(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/coupons', params);
  }

  async searchReturns(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/returns', params);
  }

  async searchRefunds(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/refunds', params);
  }
}

export default new SearchService();
