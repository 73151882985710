<template>
  <div class="modal fade" id="editShippingModal" tabindex="-1" aria-labelledby="editShippingModal" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title">Modes de livraison disponibles</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div v-if="cart.shipping_methods" class="row g-3 align-items-center">
            <table class="table table-striped text-start align-middle">
              <thead>
                <tr>
                  <th>Mode de livraison</th>
                  <th>Transporteur</th>
                  <th>Date de livraison</th>
                  <th>Prix</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(method, index) in cart.shipping_methods" :key="index">
                  <td>{{ method.carrier_title }}</td>
                  <td>{{ method.method_title }}</td>
                  <td>-</td>
                  <td>{{ round(method.price_incl_tax,2) }} €</td>
                  <td class="text-center">
                    <button type="button" class="btn btn-success btn-sm" @click="updateShippingMethod({ shipping_method: method })" data-bs-dismiss="modal">Séléctionner</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import { round } from '@/utils/tools';

export default {
  computed: {
    ...mapGetters('cart', [
      'cart'
    ]),
  },
  methods: {
    round,
    ...mapActions('cart', [
      'updateShippingMethod',
    ]),
  },
};
</script>