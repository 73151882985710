<template>
  <td class="py-3">
    <router-link :to="'/products/' + product.id" class="text-black text-decoration-none">
      {{ product.sku }}
    </router-link>
  </td>
  <td class="py-3">
    <img v-if="product && product.media_gallery_entries && product.media_gallery_entries.length > 0" 
      class="img-fluid border h-64" 
      :src="productImageUrl(product.media_gallery_entries[0].file)" 
      alt="Product Image">
  </td>
  <td class="py-3">
    <router-link :to="'/products/' + product.id" class="text-black text-decoration-none">
      {{ product.name }}
      {{ productParfum ? ' - ' + productParfum : null }}
      {{ productColor ? ' - ' + productColor : null }}
      {{ productSize ? ' - Taille : ' + productSize : null }}
    </router-link>
  </td>
  <td class="py-3">{{ formatDate(product.created_at).date }}</td>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate, productImageUrl } from '@/utils/tools';

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapGetters('attributes', ['attributes']),
    productBrand() {
      return this.attributes?.brands?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'manufacturer')?.value)?.label;
    },
    productColor() {
      return this.attributes?.colors?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'couleur')?.value)?.label;
    },
    productSize() {
      return this.attributes?.sizes?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'taille')?.value)?.label;
    },
    productSupplier() {
      return this.attributes?.suppliers?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'nom_fournisseur')?.value)?.label;
    },
    productParfum() {
      return this.attributes.parfumes.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'parfum')?.value)?.label;
    },
  },
  methods: {
    formatDate,
    productImageUrl,
  }
};
</script>