<template>
  <div class="row h-100 bg-light">
    <div class="col-12 h-auto px-5 pt-5">

      <div class="row mb-4">
        <div class="col-12">
          <h1>Bonjour {{ cookie.user.firstname }}</h1>
        </div>
      </div>

      <div class="row">
        <div v-for="(tile, index) in cookie.user.menu" :key="index" class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-4">
          <router-link :to="tile.link" class="card h-100 text-decoration-none">
            <div class="card-body text-center">
              <i :class="`bi ${tile.icon} fs-1`"></i>
              <h5 class="card-title mt-3">{{ tile.label }}</h5>
            </div>
          </router-link>
        </div>
      </div>

      <div class="row">
        <div v-if="cookie.user.id_profile == 1 || cookie.user.id_profile == 2 || cookie.user.id_profile == 4" class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-4">
          <div class="card h-100 text-decoration-none"  data-bs-toggle="modal" data-bs-target="#viewClickCollectModal">
            <div class="card-body text-center">
              <loader v-if="search.loading" />
              <span v-else-if="search.orders" class="fs-1">{{ search.orders.length }}</span>
              <h5 class="card-title mt-3">Click & Collect</h5>
            </div>
          </div>
        </div>
      </div>

     <view-click-collect-modal :orders="search.orders" :states="states"/>

    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ViewClickCollectModal from '@/components/modals/ViewClickCollectModal.vue';

export default {
  components: {
    ViewClickCollectModal,
  },
  watch: {
    'cookie.user.store': {
      handler(new_store, old_store) {
        if ((new_store != old_store) && new_store.id_store != 1) {
          this.fetchClickCollect();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('cookie', [
      'cookie'
    ]),
    ...mapGetters('search', [
      'search'
    ]),
    ...mapGetters('states', [
      'states'
    ]),
  },
  methods: {
    ...mapActions('search', [
      'fetchOrders',
      'clearSearch',
    ]),
    ...mapActions('states', [
      'fetchOrderStates'
    ]),
    fetchClickCollect() {
      const filters = {
        store_id: { value: 1, condition: 'eq' },
        status: { value: 'processing,order_sent_to_shop,payment_accepted,partial_order', condition: 'in' },
        storelocator_name: { value: this.cookie.user.store.name, condition: 'eq' },
      };
      const fields = [
        'entity_id',
        'increment_id',
        'created_at',
        'customer_firstname',
        'customer_lastname',
        'grand_total',
        'status',
        'updated_at',
      ];
      const operation = 'and';
      const limit = 50;
      this.fetchOrders({ filters, operation, limit, fields });
    }
  },
  mounted() {
    if (!this.states?.orders) {
      this.fetchOrderStates();
    }
  },
  unmounted() {
    this.clearSearch();
  }
};
</script>