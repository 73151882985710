import productService from '@/services/productService';

export default {
  namespaced: true,
  state: {
    product: null,
  },
  mutations: {
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_PRODUCT_TABS(state, tabs) {
      state.product = { ...state.product, tabs };
    },
    SET_ACTIVE_TAB(state, activetab) {
      state.product = { ...state.product, activetab: state.product.activetab == activetab ? null : activetab };
    },
    SET_PRODUCT_STOCKS(state, stocks) {
      state.product =  { ...state.product, stocks };
    },
    SET_PRODUCT_STOCK(state, stock) {
      state.product = { ...state.product, stock };
    },
    SET_ERROR(state, error) {
      state.product.error = error;
    },
    CLEAR_PRODUCT(state) {
      state.product = null;
    },
  },
  actions: {
    async fetchProduct({ state, commit }, product_id) {
      try {
        commit('SET_PRODUCT', await productService.getProduct(product_id));
        commit('SET_PRODUCT_TABS', await productService.getProductTabs(state.product.id));
      } catch (error) {
        commit('SET_ERROR', error);
      }
    },
    async fetchProductBySku({ commit, dispatch }, sku) {
      try {
        dispatch('loader/load', true, { root: true });
        commit('SET_PRODUCT', await productService.getProductBySku(sku));
      } catch (error) {
        dispatch('loader/error', `Failed to fetch product: ${error.message}`, { root: true });
      } finally {
        dispatch('loader/load', false, { root: true });
      }
    },
    async fetchProductStockInStore({ commit }, { id, source_code }) {
      try {
        commit('SET_PRODUCT_STOCK', await productService.getProductStockInStore(id, source_code));
      } catch (error) {
        console.log(error)
      }
    },
    async fetchProductStocks({ state, commit }) {
      try {
        commit('SET_PRODUCT_STOCKS', await productService.getProductStocks(state.product.id));
      } catch (error) {
        console.error(error);
      }
    },
    async addSources({ state, dispatch }, payload) {
      try {
        const response = await productService.addSources(state.product.id, payload);
        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed to add source.' }, { root: true });
      }
    },
    async updateStock({ dispatch }, { id, sku, source_code, quantity }) {
      const sourceItems = {
        sourceItems: [{
          sku: sku,
          source_code: source_code,
          quantity: quantity,
          status: 1,
        }]
      };
      try {
        await productService.updateStock(id, sourceItems);
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed to update stock.' }, { root: true });
      }
    },
    async updateThreshold({ state, dispatch }, { sku, id_stock_source, threshold }) {
      try {
        const response = await productService.updateThreshold(state.product.id, {
          sku: sku,
          id_stock_source: id_stock_source,
          threshold: threshold,
        });

        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed to update stock.' }, { root: true });
      }
    },
    async setActiveTab({ commit }, tab) {
      commit('SET_ACTIVE_TAB', tab);
    },
    async clearProduct({ commit }) {
      commit('CLEAR_PRODUCT');
    },
  },
  getters: {
    product: (state) => state.product,
  },
};
