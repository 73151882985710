import orderService from '@/services/orderService';

export default {
  namespaced: true,
  state: {
    entity_id: null,
    order: null,
  },
  mutations: {
    SET_ORDER(state, order) {
      state.order = order;
    },
    SET_ORDER_TABS(state, tabs) {
      state.order = { ...state.order, tabs };
    },
    SET_ACTIVE_TAB(state, activetab) {
      state.order = { ...state.order, activetab: state.order.activetab == activetab ? null : activetab };
    },
    SET_WORKPLAN(state, workplan) {
      state.order = { ...state.order, activeplan: workplan.component };
      state.order = { ...state.order, workplan: workplan.element };
    },
    SET_ORDER_PREPARATIONS(state, preparations) {
      state.order = { ...state.order, preparations };
    },
    SET_ORDER_RETURNS(state, returns) {
      state.order = { ...state.order, returns };
    },
    SET_ORDER_REFUNDS(state, refunds) {
      state.order = { ...state.order, refunds };
    },
    SET_ORDER_INVOICES(state, invoices) {
      state.order = { ...state.order, invoices };
    },
    SET_ORDER_CARRIERS(state, carriers) {
      state.order = { ...state.order, carriers };
    },
    SET_ORDER_BADGES(state, badges) {
      state.order = { ...state.order, badges };
    },
    SET_ORDER_GIFTS(state, gifts) {
      state.order = { ...state.order, gifts };
    },
    SET_ORDER_STORES(state, stores) {
      state.order = { ...state.order, stores };
    },
    SET_GUEST_ORDERS(state, guest_orders) {
      state.order = { ...state.order, guest_orders };
    },
    SET_ERROR(state, error) {
      state.order.error = error;
    },
    CLEAR_ORDER(state) {
      state.order = null;
    },
  },
  actions: {
    async fetchOrder({ state, commit }, order_id) {
      try {
        commit('SET_ORDER', await orderService.getOrder(order_id));
        commit('SET_ORDER_TABS', await orderService.getOrderTabs(state.order.entity_id));
        commit('SET_ORDER_BADGES', await orderService.getOrderBadges(state.order.entity_id));
        commit('SET_ORDER_CARRIERS', await orderService.getOrderCarriers(state.order.entity_id));
      } catch (error) {
        commit('SET_ERROR', error);
      }
    },
    async fetchGuestOrders({ state, commit }) {
      try {
        commit('SET_GUEST_ORDERS', await orderService.getGuestOrders(state.order.entity_id, state.order.customer_email));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderPreparations({ state, commit }) {
      try {
        const preparations = await orderService.getOrderPreparations(state.order.increment_id);
        const shipments = await orderService.getOrderShipments(state.order.entity_id);
        commit('SET_ORDER_PREPARATIONS', [...preparations.reverse(), ...shipments.reverse()]);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderReturns({ state, commit }) {
      try {
        commit('SET_ORDER_RETURNS', await orderService.getOrderReturns(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderRefunds({ state, commit }) {
      try {
        commit('SET_ORDER_REFUNDS', await orderService.getOrderRefunds(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderInvoices({ state, commit }) {
      try {
        commit('SET_ORDER_INVOICES', await orderService.getOrderInvoices(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderGifts({ state, commit }) {
      try {
        commit('SET_ORDER_GIFTS', await orderService.getOrderGifts(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderStores({ state, commit }) {
      try {
        commit('SET_ORDER_STORES', await orderService.getOrderStores(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async addProduct({ state, dispatch }, product) {
      state.order.items.push({
        sku: product.sku,
        name: product.name,
        product_type: product.type_id,
        product_id: product.id,
        price: 0,
        qty_ordered: 1,
        qty_invoiced: 1,
        qty_shipped: 0,
        row_total_incl_tax: 0,
        weight: product.weight,
        row_weight: product.weight,
      });
      dispatch('updateOrder');
    },
    async updateOrderShippingMethod({ state, dispatch }, shipping_method) {
      state.order.extension_attributes.shipping_assignments[state.order.extension_attributes.shipping_assignments.length - 1].shipping.method = shipping_method.code;
      state.order.shipping_description = shipping_method.name;
      dispatch('updateOrder');
    },
    async updateOrderStatus({ state, dispatch }, { status, comment }) {
      await orderService.updateOrderStatus(state.order.entity_id, { status, comment });
      dispatch('fetchOrder', state.order.entity_id);
    },
    async updateOrderPaymentMethod({ state, dispatch }, { payment_method }) {
      state.order.payment.method = payment_method;
      dispatch('updateOrder');
    },
    async createRefund({ state, dispatch }, { items }) {
      await orderService.createRefund(state.order.entity_id, { items: items });
      dispatch('fetchOrder', state.order.entity_id);
    },
    async createInvoice({ state, dispatch }) {
      await orderService.createInvoice(state.order.entity_id);
      dispatch('fetchOrder', state.order.entity_id);
    },
    async updateOrder({ state }) {

      let order = { ...state.order };
      order.status_histories.forEach(history => {
        delete history.expanded;
      });
    
      // Supprimer les propriétés non native
      delete order.extension_attributes.shipping_assignments[0].shipping.address.id_pr;
      delete order.billing_address.address_type;
      delete order.shipping_address;
      delete order.carrier;
      delete order.fees;
      delete order.tabs;
      delete order.badges;
      delete order.carriers;
      delete order.activetab;
      delete order.preparations;
      delete order.returns;
      delete order.refunds;
      delete order.invoices;
      delete order.activeplan;
      delete order.workplan;
      delete order.stores;
      delete order.guest_orders;
    
      await orderService.updateOrder(state.order.entity_id, { entity: order });
    },
    async setActiveTab({ commit }, tab) {
      commit('SET_ACTIVE_TAB', tab);
    },
    async setWorkplan({ commit }, workplan) {
      commit('SET_WORKPLAN', workplan); 
    },
    async clearOrder({ commit }) {
      commit('CLEAR_ORDER');
    }
  },
  getters: {
    order: state => state.order,
  }
};
