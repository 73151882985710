<template>
  <div class="modal fade" id="addSourceModal" tabindex="-1" aria-labelledby="addSourceModal" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="addSourceModal">Ajouter une source de stock</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <table v-if="filteredSources.length" class="table text-start">
            <thead>
              <tr>
                <th scope="col"><input type="checkbox" @change="selectAll($event)" /></th>
                <th scope="col">Code</th>
                <th scope="col">Nom</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(source, index) in filteredSources" :key="index">
                <td>
                  <input type="checkbox" :value="source" v-model="selectedSources" />
                </td>
                <td>{{ source.source_code }}</td>
                <td>{{ source.name }}</td>
              </tr>
            </tbody>
          </table>
          <p v-else class="text-muted text-center">Aucune source disponible.</p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Annuler
          </button>
          <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="addSelectedSources" :disabled="!selectedSources.length">
            Ajouter les sources sélectionnées
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectedSources: [],
    };
  },
  computed: {
    ...mapGetters('inventory', ['inventory']),
    ...mapGetters('product', ['product']),

    // Filtrer les sources déjà présentes dans product.stocks
    filteredSources() {
      const productStocks = this.product?.stocks || [];
      const inventorySources = this.inventory?.sources || [];

      // Exclure les sources déjà dans product.stocks
      return inventorySources.filter(
        source => !productStocks.some(stock => stock.source_code === source.source_code)
      );
    },
  },
  methods: {
    ...mapActions('inventory', ['fetchSources']),
    selectAll(event) {
      this.selectedSources = event.target.checked
        ? [...this.filteredSources]
        : [];
    },
    addSelectedSources() {
      this.$emit('add-sources', this.selectedSources);
      this.selectedSources = [];
    },
  },
  mounted() {
    this.fetchSources();
  },
};
</script>


