<template>
  <div class="col d-flex flex-column h-100">
    <div class="row flex-grow-1 overflow-y-auto h-100">

      <!-- Facture détaillée -->
      <div v-if="invoiceFormat == 'invoice'" class="col-12 py-3">
        <div class="row mb-3">
          <div class="col-12">
            <div class="row mb-1">
              <div class="col-12 fw-bold">Facture #{{ data.increment_id }}</div>
            </div>
            <div class="row row-cols">
              <div class="col-12">Commande #{{ data.order_id }}</div>
              <div class="col-12">Date de la commande : {{ formatDate(data.created_at).date }}</div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <div class="row mb-1">
              <div class="col-12 fw-bold">
                Facturation
              </div>
            </div>
            <div class="row row-cols">
              <div class="col-12">{{ order.billing_address.firstname }} {{ order.billing_address.lastname }}</div>
              <div v-for="(street, index) in order.billing_address.street" :key="index" class="col-12">{{ street }}</div>
              <div class="col-12">{{ order.billing_address.postcode }}, {{ order.billing_address.city }}, {{ order.billing_address.region }}</div>
              <div class="col-12">{{ order.billing_address.country_id }}</div>
            </div>
          </div>

          <div class="col-6">
            <div class="row mb-1">
              <div class="col-12 fw-bold">
                 Livraison
              </div>
            </div>
            <div class="row row-cols">
              <div class="col-12">{{ order.shipping_address.firstname }} {{ order.shipping_address.lastname }}</div>
              <div v-for="(street, index) in order.shipping_address.street" :key="index" class="col-12">{{ street }}</div>
              <div class="col-12">{{ order.shipping_address.postcode }}, {{ order.shipping_address.city }}</div>
              <div class="col-12">{{ order.shipping_address.country_id }}</div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <div class="row mb-1">
              <div class="col-12 fw-bold">
                Mode de paiement
              </div>
            </div>
            <div class="row row-cols">
              <div class="col-12">{{ order.extension_attributes.payment_additional_info[0].value }}</div>
            </div>
          </div>

          <div class="col-6">
            <div class="row mb-1">
              <div class="col-12 fw-bold">
                Méthode de livraison
              </div>
            </div>
            <div class="row row-cols">
              <div class="col-12">{{ order.shipping_description }}</div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  <th class="px-0">Produits</th>
                  <th class="px-0">SKU</th>
                  <th class="px-0">Prix</th>
                  <th class="px-0">Quantité</th>
                  <th class="px-0">Taxe</th>
                  <th class="px-0">Sous-total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in data.items " :key="index">
                  <th v-if="product.price" class="px-0">{{ product.name }}</th>
                  <td v-if="product.price" class="px-0">{{ product.sku }}</td>
                  <td v-if="product.price" class="px-0">{{ product.base_price_incl_tax }} €</td>
                  <td v-if="product.price" class="px-0">{{ product.qty }}</td>
                  <td v-if="product.price" class="px-0">{{ product.base_tax_amount }} €</td>
                  <td v-if="product.price" class="px-0">{{ product.base_row_total_incl_tax }} €</td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="fw-bold">
                  <td class="text-end ps-0" colspan="5">Sous-total :</td>
                  <td class="px-0">{{ data.base_subtotal }} €</td>
                </tr>
                <tr class="fw-bold">
                  <td class="text-end ps-0" colspan="5">Taxe :</td>
                  <td class="px-0">{{ data.base_tax_amount }} €</td>
                </tr>
                <tr class="fw-bold">
                  <td class="text-end ps-0" colspan="5">Frais de port :</td>
                  <td class="px-0">{{ data.base_shipping_incl_tax }} €</td>
                </tr>
                <tr class="fw-bold">
                  <td class="text-end ps-0" colspan="5">Montant total : </td>
                  <td class="px-0">{{ data.base_grand_total }} €</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <!-- Ticket de Caisse -->
      <div v-else-if="invoiceFormat == 'receipt'" class="col-12 p-5" ref="receipt">
        <order-invoice-receipt :data="data" :detailledFormat="detailledFormat" :withPrice="withPrice" />
      </div>
    </div>

    <div class="row bg-light border-top py-3">
      <div class="col-12">
        <div class="row">

          <div class="col d-flex justify-content-start me-auto">
            <div class="me-3">
              <select
                id="invoice-details"
                class="form-select"
                v-model="selectedFormat"
                @change="handleFormatChange"
              >
                <option value="detailled">Facture détaillée</option>
                <option value="summary">Facture sans détails</option>
                <option value="withoutPrice">Facture sans prix</option>
              </select>
            </div>

            <button
              v-if="invoiceFormat === 'invoice'"
              class="btn btn-primary me-3"
              @click="toggleInvoiceFormat"
            >
              Format ticket de caisse
            </button>
            <button
              v-else
              class="btn btn-primary me-3"
              @click="toggleInvoiceFormat"
            >
              Format A4
            </button>
          </div>

          <div class="col d-flex justify-content-end">
            <button class="btn btn-success me-3" @click="printInvoice(invoiceFormat)">Imprimer</button>
            <button class="btn btn-success me-3">Envoyer par e-mail</button>
            <button class="btn btn-danger" @click="closeWorkplan">Fermer</button>
          </div>
          
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/tools';
import OrderInvoiceReceipt from '@/components/order/OrderInvoiceReceipt';

export default {
  components: {
    OrderInvoiceReceipt,
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      detailledFormat: true,
      withPrice: true,
      invoiceFormat: 'invoice',
      selectedFormat: 'detailled', // Default format selection
    }
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('cookie', [
      'cookie'
    ]),
  },
  methods: {
    formatDate,
    ...mapActions('address', [
      'fetchCountries'
    ]),
    closeWorkplan() {
      this.$emit('close-workplan');
    },
    toggleInvoiceFormat() {
      this.invoiceFormat = this.invoiceFormat === 'invoice' ? 'receipt' : 'invoice';
    },
    handleFormatChange() {
      if (this.selectedFormat === 'detailled') {
        this.detailledFormat = true;
        this.withPrice = true;
      } else if (this.selectedFormat === 'summary') {
        this.detailledFormat = false;
        this.withPrice = true;
      } else if (this.selectedFormat === 'withoutPrice') {
        this.detailledFormat = true;
        this.withPrice = false;
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async printInvoice(invoiceFormat) {
      let printContent;
      // Récupérer le format à imprimer
      if (invoiceFormat === 'invoice') {
        printContent = this.$refs.invoice;
      } else if (invoiceFormat === 'receipt') {
        printContent = this.$refs.receipt;
      }
      // Créer une nouvelle fenêtre pour l'impression
      const newWindow = window.open('', '', 'width=300');
      newWindow.document.write('<html><head><title>Imprimer</title>');
      newWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet">');
      newWindow.document.write('<link href="https://fonts.googleapis.com/css2?family=Roboto" rel="stylesheet">');
      newWindow.document.write('<style>#receipt{font-family:"Roboto","Helvetica","Arial","sans-serif";-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color: #000;}.font-ean{font:50px "code128"}.fs-sm{font-size: 0.8rem;}@media print{.btn{display:none;}}</style>');
      newWindow.document.write('</head><body>');
      newWindow.document.write(printContent.outerHTML);
      newWindow.document.write('</body></html>');
      newWindow.document.close();
      // Pause de 500 ms pour s'assurer que le contenu est chargé
      await this.sleep(300);
      // Focus la fenêtre et lancer l'impression Chrome
      newWindow.focus();
      newWindow.print();
      newWindow.close();
    },
    setDefaultInvoiceFormat() {
      if (this.cookie.user.id_profile == 4) {
        this.invoiceFormat = 'receipt';
      }
    }
  },
  mounted() {
    this.fetchCountries();
    this.setDefaultInvoiceFormat();
  }
}
</script>
  