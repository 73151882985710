<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div class="row p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Réception à {{ user.store.name }}</h1>
        </div>
      </div>

      <div class="row h-64 justify-content-center border-bottom">
        <div class="col-xxl-10 col-12 d-flex">
          <search-bar ref="searchBar" :keywords="keywords" placeholder="Scannez un code-barre..." @search="onSearch" />
        </div>
      </div>

      <div v-if="search.loading" class="row h-50 overflow-auto">
        <loader />
      </div>

      <div v-else-if="product" class="row h-100 justify-content-center overflow-y-auto">
        <div class="col-auto">
          <div class="row justify-content-center p-3">
            <div class="col-4 mb-3">
              <img v-if="product.media_gallery_entries && product.media_gallery_entries.length > 0" class="img-fluid" :src="productImageUrl(product.media_gallery_entries[0].file)" alt="Product Image">
              <img v-else class="img-fluid" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
            </div>
            <div class="col-auto">
              <div class="row d-flex flex-column">
                <div class="col-auto fs-4 fw-bold mb-3">
                  {{ product.name }}
                  {{ productParfum ?? null}}
                  {{ productColor ?? null }}
                  {{ productSize ? ' - Taille : ' + productSize : null }}
                </div>
                <div class="col-auto fs-5 mb-2"><strong>Stock : </strong>{{ product?.stock?.quantity ?? 'chargement ...'}}</div>
                <div class="col-auto fs-5 mb-2"><strong>Seuil : </strong>{{ product?.stock?.threshold ?? 'chargement ...' }}</div>
                <div class="col-auto fs-5 mb-3"><strong>Localisation : </strong></div>
                <div class="col-auto mb-2">
                  <div class="input-group">
                    <input ref="inputQuantity" type="number" class="form-control" v-model="quantity" placeholder="Quantité à ajouter">
                    <button class="btn btn-primary" @click="addToStock">Ajouter au stock</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="search.error" class="mt-3">
        <div class="alert alert-primary text-start" role="alert">
          {{ search.error }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { productImageUrl } from '@/utils/tools';
import SearchBar from '@/components/common/SearchBar';

export default {
  components: {
    SearchBar,
  },
  data() {
    return {
      keywords: '',
      quantity: null,
    };
  },
  watch: {
    'user.store': {
      handler(new_store, old_store) {
        if (new_store != old_store) {
          this.fetchStore(new_store);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('attributes', ['attributes']),
    ...mapGetters('product', ['product']),
    ...mapGetters('search', ['search']),
    ...mapGetters('cookie', ['user']),
    productColor() {
      return this.attributes?.colors?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'couleur')?.value)?.label;
    },
    productSize() {
      return this.attributes?.sizes?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'taille')?.value)?.label;
    },
    productParfum() {
      return this.attributes.parfumes.find(
        option => option.value == this.data?.custom_attributes?.find(
          attr => attr.attribute_code == 'parfum')?.value)?.label;
    },
  },
  methods: {
    ...mapActions('product', ['fetchProduct', 'fetchProductStockInStore', 'updateStock', 'clearProduct']),
    ...mapActions('search', ['fetchProducts', 'clearSearch']),
    ...mapActions('store', ['fetchStore']),
    productImageUrl,
    async onSearch(keywords) {
      this.keywords = keywords;
      this.clearProduct();
      await this.fetchProducts({ 
        filters: {  
          ean: { value: '%' + keywords.trim() + '%', condition: 'like' },
          type_id: { value: 'simple', condition: 'eq' }
        },
        operation: 'and', 
        limit: 1, 
        fields: ['id','sku'] 
      });
      if (this.search.products?.length > 0) {
        await this.fetchProduct(this.search.products[0].id);
        await this.fetchProductStockInStore({ id: this.product.id, source_code: this.user.store.source_code });
        this.keywords = '';
        this.$refs.inputQuantity.focus();
      } else {
        this.$refs.searchBar.$el.querySelector('input').focus();
      }
    },
    async addToStock() {
      await this.updateStock({
        id: this.product.id,
        sku: this.product.sku,
        source_code: this.user.store.source_code,
        quantity: this.product.stock.quantity + this.quantity,
      });
      this.fetchProductStockInStore({ id: this.product.id, source_code: this.user.store.source_code });
      this.$refs.searchBar.$el.querySelector('input').focus();
      this.quantity = 0;
    }
  },
  mounted() {
    this.fetchStore(this.user.store);
  },
  unmounted() {
    this.clearSearch();
    this.clearProduct();
  }
};
</script>
