<template>
  <div class="row h-64 d-flex align-content-center align-items-center justify-content-between border-bottom">
    <ul v-if="product && product.tabs" class="nav h-100">

      <li v-for="(tab, index) in product.tabs" :key="index" @click.prevent="setActiveTab(tab)" class="d-flex align-items-center" :class="{ 'bg-light': tab.id_product_tab == product?.activetab?.id_product_tab }">
        <a class="nav-link d-flex align-items-center position-relative" href="#">
          <i class="nav-item text-black fs-3" :class="['bi', tab.icon]" :title="tab.label"></i>
          <span v-if="product.badges && tab.code && product.badges[tab.code] > 0" class="position-absolute start-75 translate-middle badge rounded-pill bg-primary">
            {{ product.badges[tab.code] }}
          </span>
        </a>
      </li>

      <li class="d-flex align-items-center ms-auto">
        <a class="nav-link d-flex align-items-center position-relative" href="#" data-bs-toggle="offcanvas" data-bs-target="#productHistory" aria-controls="productHistory">
          <i class="nav-item text-black fs-3 bi bi-clock-history" title="Historique"></i>
        </a>
      </li>

    </ul>
  </div>

  <div v-if="product.activetab" class="row flex-grow-1 border-bottom overflow-x-scroll py-3 bg-white" >
    <component :is="product.activetab.component" :store="store" @load-workplan="loadWorkplan"/>
  </div>

  <div v-if="product.activeplan" class="row flex-grow-1 overflow-auto">
    <component :is="product.activeplan" :data="product.workplan" @close-workplan="closeWorkplan"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProductStocks from '@/components/product/ProductStocks.vue';

export default {
  components: {
    ProductStocks,
  },
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('product', ['product']),
  },
  methods: {
    ...mapActions('product', [
      'setActiveTab', 
      'setWorkplan'
    ]),
    loadWorkplan(component, element) {
      this.setWorkplan({ component, element });
    },
    closeWorkplan() {
      this.setWorkplan({ component: null, element: null });
    },
  },
};
</script>
