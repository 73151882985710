<template>

  <div v-if="cart && cart.loading" class="row h-100 overflow-auto">
    <loader />
  </div>

  <div v-else-if="cart && cart.error" class="row h-100">
    <div class="row justify-content-center align-items-center">
      {{ error }}
    </div>
  </div>

  <div v-else-if="cart || create" class="row h-100 overflow-auto">
    <div v-if="!cart || !cart.reserved_order_id" class="col-12 h-100 overflow-auto">

      <cart-create v-if="create" @close-create-cart="closeCreateCart" />
      <cart-checkout v-else-if="checkout" />
        
      <div v-else class="row h-100 overflow-auto">
        <cart-catalog @view-product="onViewProduct" @add-product="addProductToCart" />

        <div class="col-xxl-3 col-md-4 d-flex flex-column h-100">
          <cart-summary />
          <cart-customer />
          <cart-products :loading="load_products" @update-quantity="updateQuantity" @delete-product="deleteProduct" />
        </div>

      </div>

    </div>
    <div v-else class="col-12 h-100 overflow-auto">
      <div class="row h-100 align-items-center">
        <div class="col-12 text-center">
          Une commande existe déjà pour ce panier : {{ cart.reserved_order_id }}
        </div>
      </div>
    </div>

  </div>

  <div v-else class="row h-100 overflow-auto">
    <loader />
  </div>

</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import CartCreate from '@/components/cart/CartCreate';
import CartCatalog from '@/components/cart/CartCatalog';
import CartSummary from '@/components/cart/CartSummary';
import CartCustomer from '@/components/cart/CartCustomer';
import CartProducts from '@/components/cart/CartProducts';
import CartCheckout from '@/components/cart/CartCheckout';

export default {
  components: {
    CartCreate,
    CartCatalog,
    CartSummary,
    CartCustomer,
    CartProducts,
    CartCheckout,
  },
  props: {
    cart_id: {
      type: Number,
      required: true,
    },
    checkout: {
      type: Boolean,
      default: false
    }
  },  
  data() {
    return {
      load_products: false,
      create: false,
    };
  },
  watch: {
    'user.store': {
      handler() {
        this.fetchCartOrCreate();
      },
      immediate: true,
    },
    cart_id: 'fetchCartOrCreate',
  },
  computed: {
    ...mapGetters('cart', ['cart']),
    ...mapGetters('cookie', ['user']),
  },
  methods: {
    ...mapActions('cart', [
      'fetchCart', 
      'fetchCartTotals', 
      'fetchShippingAddress', 
      'fetchShippingMethods', 
      'estimateShippingInformation', 
      'fetchShippingMethod',
      'fetchCartCarriers',
      'fetchPaymentMethods',
      'fetchCartUser',
      'addProduct', 
      'updateQuantity', 
      'deleteProduct', 
      'clearCart'
    ]),
    ...mapActions('search', ['clearSearch' ]),
    ...mapActions('store', ['fetchStoreUsers']),
    async fetchCartOrCreate() {
      if (this.cart_id == 'create') {
        this.create = true;
        this.clearCart();
      } else if (this.cart_id) {
        await this.fetchCart(this.cart_id);
        await this.fetchCartTotals();
        await this.fetchShippingAddress();
        await this.fetchShippingMethods();
          if (this.cart.shipping_methods.length > 0) {
            this.fetchShippingMethod();
          } this.fetchCartCarriers();
        this.fetchPaymentMethods();
        this.fetchCartUser();

        this.fetchStoreUsers(this.cart.store_id);
      }
    },
    closeCreateCart() {
      this.create = false;
    },
    async addProductToCart(product) {
      await this.addProduct(product);
    }
  },
  created() {
    if (!this.cart || this.create) {
      this.fetchCartOrCreate();
    }
  },
  unmounted() {
    this.clearCart();
    this.clearSearch();
  }
};
</script>