import backend from './backendService';

class CartService {

  async createCart(payload, store_code) {
    return backend.post('/carts', payload, store_code);
  }

  async getCart(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}`, false, store_code);
  }
    
  async getCartCustomer(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/customer`, false, store_code);
  }
  
  async getCartProducts(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/products`, false, store_code);
  }

  async getCartTotals(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/totals`, false, store_code);
  }

  async getCartUser(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/user`, false, store_code);
  }

  async getCartBillingAddress(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/billing-address`, false, store_code);
  }

  async getCartShippingAddress(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/shipping-address`, false, store_code);
  }
  
  async getCartShippingMethods(cart_id,store_code) {
    return backend.get(`/carts/${cart_id}/shipping-methods`, false, store_code);
  }

  async getCartShippingMethod(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/shipping-method`, false, store_code);
  }

  async getCartCarriers(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/carriers`, false, store_code);
  }

  async getCartStore(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/store`, false, store_code);
  }

  async getCartPaymentMethods(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/payment-methods`, false, store_code);
  }

  async updateCartUser(cart_id, id_user, store_code) {
    return backend.put(`/carts/${cart_id}/user`, id_user, store_code);
  }

  async addCustomer(cart_id, customer, store_code) {
    return backend.put(`/carts/${cart_id}/customer`, customer, store_code);
  }

  async addCoupon(cart_id, couponCode, store_code) {
    return backend.post(`/carts/${cart_id}/coupons/${couponCode}`, false, store_code);
  }

  async setGiftWrapToCart(cart_id, payload, store_code) {
    return backend.post(`/carts/${cart_id}/gift-wrap`, payload, store_code);
  }

  async addProduct(cart_id, cartItem, store_code) {
    return backend.post(`/carts/${cart_id}/items`, cartItem, store_code);
  }

  async updateQuantity(cart_id, itemId, cartItem, store_code) {
    return backend.put(`/carts/${cart_id}/items/${itemId}`, cartItem, store_code);
  }

  async deleteProduct(cart_id, itemId, store_code) {
    return backend.delete(`/carts/${cart_id}/items/${itemId}`, false, store_code);
  }

  async updateBillingAddress(cart_id, billingAddress, store_code) {
    return backend.post(`/carts/${cart_id}/billing-address`, billingAddress, store_code);
  }

  async setShippingInformation(cart_id, shippingInformation, store_code) {
    return backend.post(`/carts/${cart_id}/shipping-information`, shippingInformation, store_code);
  }

  async getPaymentMethods(cart_id, store_code) {
    return backend.get(`/carts/${cart_id}/payment-methods`, false, store_code);
  }

  async avaibleAdyenPaymentMethods(cart_id, cart_total, store_code) {
    return backend.post(`/carts/${cart_id}/adyen-methods`, cart_total, store_code);
  }

  async createAdyenPayment(cart_id, cart_total, store_code) {
    return backend.post(`/carts/${cart_id}/adyen-payment`, cart_total, store_code);
  }

  async placeOrder(cart_id, paymentMethod, store_code) {
    return backend.put(`/carts/${cart_id}/order`, paymentMethod, store_code);
  }

  async createInvoice(order_id, notifyCapture, store_code) {
    return backend.post(`/orders/${order_id}/invoice`, notifyCapture, store_code);
  }

}

export default new CartService();
