import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';

import User from '@/views/User.vue';

/*
import UserCreate from '@/components/user/UserCreate.vue';
import UserProfile from '@/components/user/UserProfile.vue';
*/

import Search from '@/views/Search.vue';
import SearchCarts from '@/components/search/SearchCarts.vue';
import SearchCustomers from '@/components/search/SearchCustomers.vue';
import SearchOrders from '@/components/search/SearchOrders.vue';
import SearchProducts from '@/components/search/SearchProducts.vue';
import SearchReturns from '@/components/search/SearchReturns.vue';
import SearchRefunds from '@/components/search/SearchRefunds.vue';

import Cart from '@/views/Cart.vue';
import Order from '@/views/Order.vue';
import Product from '@/views/Product.vue';

import Cashier from '@/views/Cashier.vue';
import Inventory from '@/views/Inventory.vue';
import Locations from '@/views/Locations.vue';
import Picks from '@/views/Picks.vue';
import Receptions from '@/views/Receptions.vue';
import Suppliers from '@/views/Suppliers.vue';
import Switchboard from '@/views/Switchboard.vue';
import Traffic from '@/views/Traffic.vue';
import Statistics from '@/views/Statistics.vue';

import store from '@/store';

const routes = [
  { path: '/', component: Home, meta: { requiresAuth: true, title: 'Accueil' } },
  { path: '/login', component: Login, meta: { title: 'Connexion' } },

  { path: '/users/:id_user/', component: User, name: 'User', props: (route) => ({ id_user: route.params.id_user }), meta: { requiresAuth: true, title: 'Utilisateur' } },

  { path: '/carts', component: Search, children: [{ path: '', component: SearchCarts, name: 'Carts', meta: { requiresAuth: true, title: 'Recherche paniers' }}]},
  { path: '/customers', component: Search, children: [{ path: '', component: SearchCustomers, name: 'Customers', meta: { requiresAuth: true, title: 'Recherche clients' }}]},
  { path: '/orders', component: Search, children: [{ path: '', component: SearchOrders, name: 'Orders', meta: { requiresAuth: true, title: 'Recherche commandes' }}]},
  { path: '/products', component: Search, children: [{ path: '', component: SearchProducts, name: 'Products', meta: { requiresAuth: true, title: 'Recherche produits' }}]},
  { path: '/returns', component: Search, children: [{ path: '', component: SearchReturns, name: 'Returns', meta: { requiresAuth: true, title: 'Recherche retours' }}]},
  { path: '/refunds', component: Search, children: [{ path: '', component: SearchRefunds, name: 'Refunds', meta: { requiresAuth: true, title: 'Recherche remboursements' }}]},

  { path: '/carts/:cart_id', component: Cart, name: 'Cart', props: (route) => ({ cart_id: route.params.cart_id, checkout: false }), meta: { requiresAuth: true, title: 'Panier' }},
  { path: '/carts/:cart_id/checkout', component: Cart, name: 'Checkout', props: (route) => ({ cart_id: route.params.cart_id, checkout: true }), meta: { requiresAuth: true, title: 'Caisse' }},
  { path: '/orders/:order_id', component: Order, name: 'Order', props: (route) => ({ order_id: route.params.order_id }), meta: { requiresAuth: true, title: 'Commande' }},
  { path: '/orders/:order_id/:activetab', component: Order, name: 'OrderTabs', props: (route) => ({ order_id: route.params.order_id, activetab: route.params.activetab }), meta: { requiresAuth: true, title: 'Commande' }},
  { path: '/orders/:order_id/:activetab/:activeplan', component: Order, name: 'OrderWorkplan', props: (route) => ({ order_id: route.params.order_id, activetab: route.params.activetab, activeplan: route.params.activeplan }), meta: { requiresAuth: true, title: 'Commande' }},
  { path: '/products/:product_id', component: Product, name: 'Product', props: (route) => ({ product_id: route.params.product_id }), meta: { requiresAuth: true, title: 'Produit' }},

  { path: '/cashier', component: Cashier, name: 'Cashier', meta: { requiresAuth: true, title: 'Caisse' }},
  { path: '/inventory', component: Inventory, name: 'Inventory', meta: { requiresAuth: true, title: 'Inventaire' }},
  { path: '/locations', component: Locations, name: 'Locations', meta: { requiresAuth: true, title: 'Localisations' }},
  { path: '/receptions', component: Receptions, name: 'Receptions', meta: { requiresAuth: true, title: 'Réceptions' }},
  { path: '/suppliers', component: Suppliers, name: 'Suppliers', meta: { requiresAuth: true, title: 'Fournisseurs' }},
  { path: '/suppliers/:id_supplier', component: Suppliers, name: 'Supplier', props: (route) => ({ id_supplier: route.params.id_supplier }), meta: { requiresAuth: true, title: 'Fournisseurs' }},
  { path: '/switchboard', component: Switchboard, name: 'Switchboard', meta: { requiresAuth: true, title: 'Standard' }},
  { path: '/traffic', component: Traffic, name: 'Traffic', meta: { requiresAuth: true, title: 'Trafic' }},
  { path: '/statistics', component: Statistics, name: 'Statistics', meta: { requiresAuth: true, title: 'Statistiques' }},
  { path: '/picks', component: Picks, name: 'Picks', meta: { requiresAuth: true, title: 'Picks boutique' }},
  { path: '/picks/:id_pick', component: Picks, name: 'Pick', props: (route) => ({ id_pick: route.params.id_pick }), meta: { requiresAuth: true, title: 'Pick boutique' }},
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {

  let title = 'OMS - ' + to.meta.title || 'Titre par défaut';
  if (to.params.order_id) {
    title += ` ${to.params.order_id}`;
  }

  document.title = title;

  // Logique d'authentification
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['cookie/token']) {
      next({
        path: '/login',
        query: { redirect: from.fullPath }
      });
    } else {
      if (!store.getters['cookie/user']) {
        store.dispatch('cookie/fetchUser', store.getters['cookie/id_user'])
          .then(() => {
            next();
          });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});


export default router;
