import backend from './backendService';

class OrderService {
  async getOrder(order_id) {
    return backend.get(`/orders/${order_id}`);
  }

  async getOrderTabs() {
    return backend.get(`/config/orders/tabs`);
  }
  
  async getOrderBadges(order_id) {
    return backend.get(`/orders/${order_id}/badges`);
  }

  async getGuestOrders(order_id, customer_email) {
    return backend.get(`/orders/${order_id}/customer/${customer_email}`);
  }

  async getOrderPreparations(order_id) {
    return backend.get(`/orders/${order_id}/preparations`);
  }

  async getOrderShipments(order_id) {
    return backend.get(`/orders/${order_id}/shipments`);
  }

  async getOrderInvoices(order_id) {
    return backend.get(`/orders/${order_id}/invoices`);
  }

  async getOrderRefunds(order_id) {
    return backend.get(`/orders/${order_id}/refunds`);
  }

  async getOrderReturns(order_id) {
    return backend.get(`/orders/${order_id}/returns`);
  }

  async createInvoice(order_id) {
    return backend.post(`/orders/${order_id}/invoice`);
  }

  async createRefund(order_id, items) {
    return backend.post(`/orders/${order_id}/refund`, items);
  }

  async updateOrderStatus(order_id, status) {
    return backend.post(`/orders/${order_id}/status`, status);
  }
  
  async getOrderCarriers(order_id) {
    return backend.get(`/orders/${order_id}/carriers`);
  }

  async getOrderGifts(order_id) {
    return backend.get(`/orders/${order_id}/gifts`);
  }

  async getOrderStores(order_id) {
    return backend.get(`/orders/${order_id}/stores`);
  }

  async updateOrder(order_id, payload) {
    return backend.put(`/orders/${order_id}`, payload);
  }

  async getOrderEntity(increment_id) {
    return backend.get(`/orders/${increment_id}/entity`);
  }

}

export default new OrderService();
