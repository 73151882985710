<template>
  <div class="row bg-light border-bottom">
    <div class="col-12 px-5">
      
      <div class="row align-items-start bg-light py-3">
        <div class="col">
          <search-filters :filters="activeFilters" @submit.prevent="searchOrders" />
        </div>
        
        <div class="col-lg-auto col-1">
          <div class="row">
            <div class="col-lg-6 col-12 mb-3">
              <button type="submit" class="btn btn-primary" form="search">Rechercher</button>
            </div>
            <div class="col-lg-6 col-12 text-end">
              <a class="btn btn-secondary dropdown-toggle" href="#" role="button" @click="toggleFilterDropdown">
                Critères
              </a>
              <div v-if="showFilterDropdown" class="dropdown-menu show position-fixed end-0 mt-3 me-3 p-3">
                <div class="form-check" v-for="filter in filters" :key="filter.field">
                  <input class="form-check-input" type="checkbox" v-model="filter.active" />
                  <label class="form-check-label mt-1" :for="filter.field">{{ filter.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row overflow-y-auto">
    <div class="col-12 overflow-y-auto px-5 pt-5" style="max-height: 70vh;">
      <div v-if="search.loading" class="row h-100">
        <loader />
      </div>
      <div v-else-if="search.orders && search.orders.length > 0" class="col">
        <table class="table table-hover align-middle">
          <thead>
            <tr>
              <th class="col" @click="sortByColumn('source')" role="button">Source</th>
              <th class="col" @click="sortByColumn('increment_id')" role="button">Numéro de commande</th>
              <th class="col" @click="sortByColumn('created_at')" role="button">Heure</th>
              <th class="col" @click="sortByColumn('customer_firstname')" role="button">Client</th>
              <th class="col" @click="sortByColumn('grand_total')" role="button">Total</th>
              <th class="col" @click="sortByColumn('payment.method')" role="button">Mode de paiement</th>
              <th class="col" @click="sortByColumn('status')" role="button">Statut</th>
              <th class="col-3" @click="sortByColumn('shipping_description')" role="button">Transporteur</th>
              <th class="col" @click="sortByColumn('created_at')" role="button">Date de validation</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-for="order in sortedOrders" :key="order.increment_id">
              <td class="py-3">
                <img :src="sourceIcon(order.store_id)" alt="Logo" width="24" height="24" />
              </td>
              <td class="py-3">
                <router-link target="_blank" :to="'/orders/' + order.entity_id" class="text-black text-decoration-none">
                  {{ order.increment_id }}
                </router-link>
              </td>
              <td class="py-3">{{ formatDate(order.created_at).time }}</td>
              <td class="py-3">{{ order.customer_firstname }} {{ order.customer_lastname }}</td>
              <td class="py-3">{{ order.grand_total }} €</td>
              <td class="py-3">{{ order.payment?.method }}</td>
              <td class="py-3">
                <span v-if="displayState(order.status)" class="d-flex">
                  <i :class="`bi ${displayState(order.status).icon} me-1 ${displayState(order.status).color}`"></i>{{ displayState(order.status).name }}
                </span>
              </td>
              <td class="py-3">{{ order.extension_attributes?.shipping_assignments[0]?.shipping.method }} {{ isPickupInStore(order.extension_attributes?.shipping_assignments[0]?.shipping.method) ? '- ' + order.extension_attributes?.shipping_assignments[0]?.shipping.address.lastname : ''}}</td>
              <td class="py-3">{{ formatDate(order.created_at).date }}</td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination Section
        <div class="pagination mt-3">
          <button 
            :disabled="currentPage === 1" 
            @click="currentPage--; searchOrders()" 
            class="btn btn-secondary">
            Précédent
          </button>
          <span>Page {{ currentPage }} sur {{ totalPages }}</span>
          <button 
            :disabled="currentPage === totalPages" 
            @click="currentPage++; searchOrders()" 
            class="btn btn-secondary">
            Suivant
          </button>
        </div> -->
      </div> 

      <div v-else class="col text-center">
        <p>Aucune commande n'a été trouvée.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, sourceIcon } from '@/utils/tools';
import SearchFilters from '@/components/common/SearchFilters';

export default {
  components: {
    SearchFilters,
  },
  data() {

    const today = new Date();

    const endYear = today.getFullYear();
    const endMonth = (today.getMonth() + 1).toString().padStart(2, '0');
    const endDay = today.getDate().toString().padStart(2, '0');
    const end = `${endYear}-${endMonth}-${endDay}`;

    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - 30);
    const startYear = pastDate.getFullYear();
    const startMonth = (pastDate.getMonth() + 1).toString().padStart(2, '0');
    const startDay = pastDate.getDate().toString().padStart(2, '0');
    const start = `${startYear}-${startMonth}-${startDay}`;

    return {
      showFilterDropdown: false,
      filters: [
        { field: 'created_at', label: 'Plage de date', type: 'date', active: true, value: [start, end], condition: 'range' },
        { field: 'store_id', label: 'Source', type: 'select', condition: 'in', active: true, value: [], options: [], name: 'boutiques' },
        { field: 'status', label: 'Statut de commande', type: 'select', condition: 'in', active: true, value: [], options: [], name: 'status' },
        { field: 'shipping_method', label: 'Transporteur', type: 'select', condition: 'in', active: true, value: [], options: [], name: 'transporteurs' },
        { field: 'increment_id', label: 'Numéro de commande', type: 'text', active: true, value: '', condition: 'eq' },
        { field: 'customer_email', label: 'Adresse e-mail', type: 'text', active: true, value: '', condition: 'like' },
        { field: 'customer_firstname', label: 'Prénom', type: 'text', active: false, value: '', condition: 'eq' },
        { field: 'customer_lastname', label: 'Nom', type: 'text', active: false, value: '', condition: 'eq' },
        { field: 'total_paid_min', label: 'Montant total min', type: 'number', active: false, value: null, condition: 'gte' },
        { field: 'total_paid_max', label: 'Montant total max', type: 'number', active: false, value: null, condition: 'lte' },
      ],
      sortBy: 'created_at', // Champ de tri par défaut
      sortDesc: true,       // Tri descendant par défaut
      currentPage: 1,       // Page actuelle
      pageSize: 100,        // Nombre d'éléments par page
      totalOrders: 0,       // Nombre total d'éléments
    };
  },
  computed: {
    ...mapGetters('search', ['search']),
    ...mapGetters('states', ['states']),
    activeFilters() {
      return this.filters.filter(filter => filter.active);
    },
    sortedOrders() {
      let orders = [...this.search.orders];
      const compare = (a, b) => {
        const field = this.sortBy;
        if (a[field] < b[field]) return this.sortDesc ? 1 : -1;
        if (a[field] > b[field]) return this.sortDesc ? -1 : 1;
        return 0;
      };
      return orders.sort(compare);
    },
    totalPages() {
      return Math.ceil(this.totalOrders / this.pageSize);
    },
  },
  methods: {
    formatDate,
    sourceIcon,
    ...mapActions('search', ['fetchOrders']),
    ...mapActions('states', ['fetchOrderStates', 'fetchCarriers']),
    toggleFilterDropdown() {
      this.showFilterDropdown = !this.showFilterDropdown;
    },
    displayState(status) {
      return this.states && this.states.orders ? this.states.orders.find(state => state.order_status == status) : null;
    },
    displayCarrier(shipping_method) {
      return this.states && this.states.carriers ? this.states.carriers.find(state => state.code == shipping_method) : null;
    },
    isPickupInStore(shipping_method) {
      return shipping_method === 'interactiv4storepickup_interactiv4storepickup';
    },
    async searchOrders() {
      const filters = this.filters.reduce((acc, filter) => {
        if (filter.active && filter.value) {
          if (filter.type == 'select' && Array.isArray(filter.value)) {
            acc[filter.field] = { value: filter.value.map(option => option.value).join(','), condition: 'in' };
          } else if (filter.condition == 'like') {
            acc[filter.field] = { value: filter.value + '%', condition: filter.condition };
          } else {
            acc[filter.field] = { value: filter.value, condition: filter.condition };
          }
        }
        return acc;
      }, {});

      const operation = 'and';
      const fields = [
        'entity_id', 
        'increment_id', 
        'store_id', 
        'created_at', 
        'customer_firstname', 
        'customer_lastname',
        'grand_total',
        'payment',
        'status',
        'extension_attributes[shipping_assignments][0][shipping]'
      ];

      const searchCriteria = {
        filters,
        operation,
        limit: this.pageSize,
        page: this.currentPage,
        fields,
      };

      const response = await this.fetchOrders(searchCriteria);

      if (response) {
        this.totalOrders = response.total_count;
      }
    },
    sortByColumn(column) {
      if (this.sortBy == column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    }
  },
  async mounted() {
    await this.fetchOrderStates();
    const status = this.filters.find(filter => filter.field == 'status');
    const states = this.states.orders.map(status => ({
      value: status.order_status,
      label: status.name,
    }));
    status.options = states;

    await this.fetchCarriers();
    const carrier = this.filters.find(filter => filter.field == 'shipping_method');
    const carriers = this.states.carriers.map(carrier => ({
      value: carrier.code,
      label: carrier.name,
    }));
    carrier.options = carriers;
    }
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: block;
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 ::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}</style>