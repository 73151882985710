import inventoryService from '@/services/inventoryService';

export default {
  namespaced: true,
  state: {
    inventory: {
      sources: [],
    },
  },
  mutations: {
    SET_SOURCES(state, sources) {
      state.inventory.sources = sources;
    },
  },
  actions: {
    async fetchSources({ commit }) {
      try {
        commit('SET_SOURCES', await inventoryService.getSources());
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {
    inventory: (state) => state.inventory,
  },
};
