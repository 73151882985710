import backend from './backendService';

class ReceptionService {
  async getReceptions() {
    return backend.get(`/receptions`);
  }

  async getStoreReceptions(id_shop) {
    return backend.get(`/shopreappro/${id_shop}`);
  }

  async getStoreReception(id_shop, id_pick) {
    return backend.get(`/shopreappro/${id_shop}/${id_pick}`);
  }

  async putStoreReception(id_shop, id_pick) {
    return backend.put(`/shopreappro/${id_shop}/${id_pick}`);
  }
}

export default new ReceptionService();
