<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div v-if="product && product.error" class="row h-100">
        <div class="row justify-content-center align-items-center">
          {{ product.error }}
        </div>
      </div>

      <div v-else-if="product" class="row h-100 overflow-auto">
        <div v-if="displaySidebar" class="col-xxl-3 col-md-4 bg-light h-100 overflow-auto border-end">

          <div class="row justify-content-center mt-3 mb-4">
            <div class="col text-start">
              <button @click="toggleSidebar" class="btn btn-sm btn-secondary" title="Cacher le volet du résumé du produit">
                <i class="bi bi-layout-sidebar-inset"></i>
              </button>
            </div>

            <div class="col-3">
              <img v-if="product && product.media_gallery_entries && product.media_gallery_entries.length > 0" 
              class="img-fluid border" 
              :src="productImageUrl(product.media_gallery_entries[0].file)" 
              alt="Product Image">
            </div>
            
            <div class="col text-end">
              <a :href="'https://shopadmin.espaceplaisir.fr/suadmin/catalog/product/edit/id/' + product.id" target="_blank" class="btn btn-sm btn-secondary">
                <i class="bi bi-back"></i>
              </a>
            </div>
          </div>

          <div class="row justify-content-center mb-5">
            <div class="col-10 h4 fw-bold bg-white border text-center p-3">{{ product.name }}</div>
          </div>

          <div v-if="productSize || productColor || productParfum" class="row justify-content-center mt-4">
            <div class="col-auto h5 fw-bold">Attributs</div>
          </div>

          <div v-if="productSize || productColor || productParfum" class="row justify-content-center mb-4">
            <div class="col-12">
              <table class="table border align-middle fs-sm">
                <tbody>
                  <tr v-if="productSize">
                    <td class="text-start">Taille</td>
                    <td class="text-end">{{ productSize }}</td>
                  </tr>
                  <tr v-if="productColor">
                    <td class="text-start">Couleur</td>
                    <td class="text-end">{{ productColor }}</td>
                  </tr>
                  <tr v-if="productParfum">
                    <td class="text-start">Parfum</td>
                    <td class="text-end">{{ productParfum }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row justify-content-center mt-4">
            <div class="col-auto h5 fw-bold">Informations</div>
          </div>

          <div class="row justify-content-center mb-4">
            <div class="col-12">
              <table class="table border align-middle fs-sm">
                <tbody>
                  <tr>
                    <td class="text-start">Marque</td>
                    <td class="text-end">{{ productBrand }}</td>
                  </tr>
                  <tr>
                    <td class="text-start">Fournisseur</td>
                    <td class="text-end">{{ productSupplier }}</td>
                  </tr>
                  <tr>
                    <td class="text-start">SKU</td>
                    <td class="text-end">{{ product.sku }}</td>
                  </tr>
                  <tr>
                    <td class="text-start">Code Barre</td>
                    <td class="text-end">{{ productEan }}</td>
                  </tr>
                  <tr>
                    <td class="text-start">ID Magento</td>
                    <td class="text-end"><a :href="'https://shopadmin.espaceplaisir.fr/suadmin/catalog/product/edit/id/' + product.id + ''" target="blank">{{ product.id }}</a></td>
                  </tr>
                  <tr>
                    <td class="text-start">Fiche WMS</td>
                    <td class="text-end"><a :href="'https://wms.belisoft.io/product/?ean=' + productEan + ''" target="blank">Ouvrir</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row justify-content-center mt-4">
            <div class="col-auto h5 fw-bold">Stocks</div>
          </div>

          <div class="row justify-content-center mb-4">
            <div class="col-12">
              <table v-if="product.stocks"  class="table border align-middle fs-sm">
                <tbody>
                  <tr v-for="(stock, index) in product.stocks" :key="index">
                    <td class="text-start">{{ stock.source_code }}</td>
                    <td class="text-end">{{ stock.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="row justify-content-center mt-4">
                <loader />
              </div>
            </div>
          </div>

        </div>

        <div v-else class="col-auto bg-light h-100 overflow-auto border-end py-3">
          <button class="btn btn-sm btn-secondary" @click="toggleSidebar" title="Afficher le volet du résumé de la commande">
            <i class="bi bi-layout-sidebar-inset"></i>
          </button>
        </div>

        <div class="col-xl-3 h-100 overflow-auto border-end">
          <div class="row d-flex align-content-center align-items-center justify-content-between border-bottom h-100">
            <div class="col-12 d-flex flex-column h-100">

              <!-- Bouton Ajouter une source -->
              <div class="row h-64 align-content-center align-items-center justify-content-between border-bottom">
                <div class="col-12 text-end">
                  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addSourceModal">
                    Ajouter une source de stock
                  </button>
                  <add-source-modal @add-sources="addSources"/>
                </div>
              </div>

              <!-- Liste des boutiques filtrées -->
              <div v-if="filteredStores.length" class="row flex-grow-1 overflow-y-auto" style="min-height: 0;">
                <div class="col-12">
                  <div v-for="(store, index) in filteredStores" :key="index" class="row text-start border-bottom">
                    <div class="col-12">
                      <div
                        class="row h-64 align-items-center"
                        @click="selectStore(store)"
                        :class="{ 'bg-primary-subtle': store.id_store === selectedStore?.id_store }"
                      >
                        <div class="col-12">{{ store.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <div class="col h-100 d-flex flex-column justify-content-flex-start overflow-auto border-end">
          <product-tabs :store="selectedStore" />
        </div>

      </div>

      <div v-else class="row h-100 overflow-auto">
        <loader />
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { productImageUrl } from '@/utils/tools';
import ProductTabs from '@/components/product/ProductTabs';
import AddSourceModal from '@/components/modals/AddSourceModal';

export default {
  components: {
    ProductTabs,
    AddSourceModal,
  },
  props: {
    product_id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    product_id: {
      deep: true,
      immediate: true,
      async handler() {
        await this.fetchProduct(this.product_id);
        await this.setActiveTab(this.product.tabs.find((tab) => tab.id_product_tab == 1));
      }
    },
  },
  computed: {
    ...mapGetters('attributes', ['attributes']),
    ...mapGetters('cookie', ['user']),
    ...mapGetters('product', ['product']),
    productEan() {
      return this.product.custom_attributes.find(attr => attr.attribute_code === 'ean')?.value || 'N/A';
    },
    filteredStores() {
      if (!this.user?.stores || !this.product?.stocks) {
        return [];
      }
      return this.user.stores.filter(store =>this.product.stocks.some(stock => stock.source_code == store.source_code));
    },
    productBrand() {
      return this.attributes?.brands?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'manufacturer')?.value)?.label;
    },
    productColor() {
      return this.attributes?.colors?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'couleur')?.value)?.label;
    },
    productSize() {
      return this.attributes?.sizes?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'taille')?.value)?.label;
    },
    productSupplier() {
      return this.attributes?.suppliers?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'nom_fournisseur')?.value)?.label;
    },
    productParfum() {
      return this.attributes?.parfumes?.find(
        option => option.value == this.product?.custom_attributes?.find(
          attr => attr.attribute_code == 'parfum')?.value)?.label;
    },
  },
  methods: {
    productImageUrl,
    ...mapActions('product', ['fetchProduct', 'addSources', 'setActiveTab', 'clearProduct']),
    ...mapActions('cookie', ['fetchUserStores']),
    selectStore(store) {
      this.selectedStore = store;
    },
    toggleSidebar() {
      this.displaySidebar = !this.displaySidebar;
    },
    addSources(sources) {
      const payload = sources.map(source => ({
        sku: this.product.sku,
        source_code: source.source_code,
        quantity: 0,
        status: 1,
        threshold: 0,
      }));
      this.addSources(payload)
      this.fetchProduct();
    },
  },
  data() {
    return {
      displaySidebar: true,
      selectedStore: null,
    };
  },
  mounted() {
    this.fetchUserStores();
    this.selectedStore = this.user.store;
  },
  unmounted() {
    this.clearProduct();
  },
};
</script>
