import receptionService from '@/services/receptionService';

export default {
  namespaced: true,
  state: {
    receptions: [],
    reception: null,
  },
  mutations: {
    SET_RECEPTIONS(state, receptions) {
      state.receptions = receptions;
    },
    SET_RECEPTION(state, reception) {
      state.reception = reception;
    },
    CLEAR_RECEPTION(state) {
      state.reception = null;
    },
  },
  actions: {
    async fetchReceptions({ commit }) {
      try {
        commit('SET_RECEPTIONS', await receptionService.getReceptions());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStoreReceptions({ commit }, id_shop) {
      try {
        commit('SET_RECEPTIONS', await receptionService.getStoreReceptions(id_shop));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStoreReception({ commit }, { id_shop, id_pick }) {
      try {
        commit('SET_RECEPTION', await receptionService.getStoreReception(id_shop, id_pick));
      } catch (error) {
        console.error(error);
      }
    },
    async validateStoreReception({ dispatch }, { id_shop, id_pick }) {
      await receptionService.putStoreReception(id_shop, id_pick)
      dispatch('fetchStoreReceptions', id_shop);
    },
    async clearReception({ commit }) {
      commit('CLEAR_RECEPTION');
    },
  },
  getters: {
    receptions: state => state.receptions,
    reception: state => state.reception,
  }
};
