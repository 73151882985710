<template>
  <div class="modal fade" id="addCouponModal" tabindex="-1" aria-labelledby="addCouponModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="addCouponModal">Ajouter un code promotionnel</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        
        <div class="modal-body">

          <form class="row align-items-center" @submit.prevent="enterSubmit">
            <div class="col-9">
              <input v-model="code" type="text" class="form-control" placeholder="Entrez un code promo" ref="focus" autofocus required>
            </div>
            <div class="col-3">
              <button type="submit" class="btn btn-primary w-100" data-bs-dismiss="modal">Ajouter</button>
            </div>
          </form>
        </div>
        
        <div class="modal-header">
          <h5 class="modal-title">Réductions disponibles</h5>
        </div>

        <div class="modal-body">
          <div v-if="reductions && reductions.length > 0" class="row g-2">
            <div v-for="coupon in reductions" :key="coupon.rule_id" class="col-3">
              <button class="btn btn-primary w-100 p-3" data-bs-dismiss="modal" @click="addCoupon(coupon.code)" >
                {{ coupon.name }}<br>
                {{ coupon.description }}
              </button>
            </div>
          </div>
        </div>

        <div class="modal-header">
          <h5 class="modal-title">Cadeaux disponibles</h5>
        </div>

        <div class="modal-body">
          <div v-if="gifts && gifts.length > 0" class="row g-2">
            <div v-for="coupon in gifts" :key="coupon.rule_id" class="col-3">
              <button class="btn btn-primary w-100 p-3" data-bs-dismiss="modal" @click="addCoupon(coupon.code)">
                {{ coupon.name }}<br>
                {{ coupon.description }}
              </button>
            </div>
          </div>
        </div>

        <div class="modal-header">
          <h5 class="modal-title">Réductions DLC courtes</h5>
        </div>

        <div class="modal-body">
          <div v-if="dlcs && dlcs.length > 0" class="row g-2">
            <div v-for="coupon in dlcs" :key="coupon.rule_id" class="col-3">
              <button class="btn btn-primary w-100 p-3" data-bs-dismiss="modal" @click="addCoupon(coupon.code)" >
                {{ coupon.name }}<br>
                {{ coupon.description }}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { round } from '@/utils/tools';

export default {
  props: {
    cart: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reductions: [
        { name: 'AVIS10', description: '- 10 € avis Google', code: 'AVIS10' },
        { name: 'BOUTIQUE10', description: '- 10 % boutique', code: 'BOUTIQUE10' },
        { name: 'FLYER15', description: '- 15 % flyer', code: 'FLYER15' },
        { name: 'UNIDAYS', description: '- 20 % étudiant', code: 'UNIDAYS' },
        { name: 'BELISOFT30', description: '- 30 % interne', code: 'BELISOFT30' },
      ],
      dlcs: [
        { name: 'DLC20', description: '- 20 % DLC courte', code: 'DLC20' },
        { name: 'DLC30', description: '- 30 % DLC courte', code: 'DLC30' },
        { name: 'DLC40', description: '- 40 % DLC courte', code: 'DLC40' },
        { name: 'DLC50', description: '- 50 % DLC courte', code: 'DLC50' },
      ],
      gifts: [
        { name: 'SWITCHX', description: '1 Switch X offert ', code: 'SWITCHX' },
      ],
      code: '',
      error: '',
    };
  },
  methods: {
    ...mapActions('cart', ['addCoupon', ]),
    round,
    async enterSubmit() {
      if (this.code.trim() != '') {
        await this.addCoupon(this.code.trim());
      }
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    }
  },
  mounted() {
    this.focusInput();
  },
};
</script>