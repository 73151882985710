<template>
  <div class="modal fade" id="viewClickCollectModal" tabindex="-1" aria-labelledby="viewClickCollectModal" aria-hidden="true" >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title user-select-auto" id="viewClickCollectModal">Click & Collect en attentes de préparation</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <div v-if="orders && orders.length" class="row p-3 pb-0">
            <table class="table table-striped text-start align-middle">
              <thead>
                <tr>
                  <th>Numéro de commande</th>
                  <th>Date de la commande</th>
                  <th>Client</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Date d'expédition</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                  <td class="py-3">
                    <router-link target="_blank" :to="'/orders/' + order.entity_id" class="text-black text-decoration-none">
                      {{ order.increment_id }}
                    </router-link>
                  </td>
                  <td class="py-3">{{ formatDate(order.created_at).full }}</td>
                  <td class="py-3">{{ order.customer_firstname }} {{ order.customer_lastname }}</td>
                  <td class="py-3">{{ order.grand_total }} €</td>
                  <td class="py-3">
                    <span v-if="displayState(order.status)" class="d-flex">
                      <i :class="`bi ${displayState(order.status).icon} me-1 ${displayState(order.status).color}`"></i>{{ displayState(order.status).name }}
                    </span>
                  </td>
                  <td class="py-3">{{ formatDate(order.updated_at).date }}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>


<script>
import { formatDate } from '@/utils/tools';

export default {
  props: {
    orders: {
      type: Object,
      required: true,
    },
    states: {
      type: Object,
      required: true,
    },
  },
  data() {

  },
  computed: {

  },
  methods: {
    formatDate,
    displayState(status) {
      return this.states && this.states.orders ? this.states.orders.find(state => state.order_status == status) : null;
    },
  },
};
</script>
