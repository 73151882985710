import productService from '@/services/productService';

export default {
  namespaced: true,
  state: {
    attributes: {
      brands: [],
      colors: [],
      parfumes: [],
      sizes: [],
      suppliers: [],
    },
  },
  mutations: {
    SET_SIZES(state, sizes) {
      state.attributes.sizes = sizes;
    },
    SET_COLORS(state, colors) {
      state.attributes.colors = colors;
    },
    SET_BRANDS(state, brands) {
      state.attributes.brands = brands;
    },
    SET_SUPPLIERS(state, suppliers) {
      state.attributes.suppliers = suppliers;
    },
    SET_PARFUMES(state, parfumes) {
      state.attributes.parfumes = parfumes;
    },
  },
  actions: {
    async fetchAttributes({ commit }) {
      try {
        commit('SET_SIZES', await productService.getProductAttribute('taille'));
        commit('SET_COLORS', await productService.getProductAttribute('couleur'));
        commit('SET_BRANDS', await productService.getProductAttribute('manufacturer'));
        commit('SET_SUPPLIERS', await productService.getProductAttribute('nom_fournisseur'));
        commit('SET_PARFUMES', await productService.getProductAttribute('parfum'));
      } catch (error) {
        console.error('Erreur lors du chargement des attributs :', error);
      }
    },
  },
  getters: {
    attributes: (state) => state.attributes,
  },
};
